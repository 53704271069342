// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgLeaderboard = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 4.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5zm.6 3A1.35 1.35 0 0 0 8.25 8.6v3.15H3.6a1.35 1.35 0 0 0-1.35 1.35v5.3c0 .746.604 1.35 1.35 1.35h16.8a1.35 1.35 0 0 0 1.35-1.35v-3.3a1.35 1.35 0 0 0-1.35-1.35h-4.65V8.6a1.35 1.35 0 0 0-1.35-1.35zm-1.35 11v-5h-4.5v5zm1.5 0h4.5v-9.5h-4.5v9.5m10.5 0h-4.5v-3h4.5z"
      clipRule="evenodd"
    />
  </svg>
);
export const LeaderboardIcon = forwardRef(SvgLeaderboard);
